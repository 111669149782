import React from "react";
import { useMarketingPagesTracking } from "../hooks/marketingPagesTracking";
import { HomepageVariantThree } from "../pagelayouts/homepage/HomepageVariantThree";

const Homepage = () => {
    useMarketingPagesTracking();

    return <HomepageVariantThree />
}

export async function getStaticProps() {
    return {
        props: {}
    }
}

export default Homepage