import React from "react"
import { MarketingMenu } from "../../components/MarketingMenu"
import { MarketingOGTags } from "../../components/MarketingOGTags"
import { FadeUpContainer } from "../../components/ReusableStyledComponents/FadeUp"
import { MarketingTextBlock } from "../../components/MarketingTextBlock"
import { VerticalSpacing } from "../../components/VerticalSpacing"
import { MarketingCTA, CTASize } from "../../components/MarketingCTA"
import { HomePageVariantCentered, HomepageVariantCustomH4, HomepageVariantCustomH2, HomepageBlockBackgroundWrapper, HomepageAuthoryTeamImage } from "./HomepageVariantThree.styles"
import { MarketingTitleBr } from "../../components/ReusableStyledComponents/MarketingMainTitle"
import { MarketingTextSVGColumn } from "../../components/MarketingTextSVGColumn"
import { HPMainBtnWrapper, HPMainFloatWrapper } from "./HomePageLayout.styles"
import { InlineLink } from "../../components/ReusableStyledComponents/InlineLink"
import { MarketingG2Banner } from "../../components/MarketingG2Banner"
import { MarketingFooter } from "../../components/MarketingFooter"
import { MarketingTextSVGHeader, PricingDesktop } from "../../components/MarketingTextSVGHeader"
import { UnstyledLink } from "../../components/ReusableStyledComponents/UnstyledLink"
import { APPLICATION_ROUTES } from "../../types/routes"
import { theme } from "../../theme/theme"
import { MarketingIconsBlock, MarketingIconBlock } from "../../components/MarketingIconsBlock"
import { SVGGeneric } from "../../components/ReusableStyledComponents/SVGGeneric"
import { MarketingGetStarted } from "../../components/MarketingGetStarted"
import dynamic from "next/dynamic"
import { MarketingAllBenefits } from "../../components/MarketingAllBenefits"
import { useWindowSize } from "../../hooks/useWindowSize"

const MarketingCarouselUser = dynamic(
    () => import('../../components/MarketingCarouselUser/MarketingCarouselUser'),
    { ssr: false }
);

type HomepageVariantThreeProps = {
    customTitle?: JSX.Element,
    userVariant?: boolean
}

export const HomepageVariantThree = ({ }: HomepageVariantThreeProps) => {
    const size = useWindowSize();
    const IS_MOBILE = !!size.width && size.width <= theme.v3.layout.sidebarLayout.mobile;

    return <>
        <MarketingOGTags
            title="Authory - Save & Showcase Everything You've Written and Created"
            description="Try the done-for-you portfolio builder that automatically backs up your bylines, podcasts, videos, and more. Test it for free."
            canonicalUrl={`${process.env.NEXT_PUBLIC_FRONTEND_URL}`}
        />
        <MarketingMenu />
        <FadeUpContainer>
            <HomepageBlockBackgroundWrapper>
                <div>
                    <VerticalSpacing top={-30}>
                        <MarketingTextSVGHeader
                            customTitleMarginBottom={20}
                            textMarginTop={30}
                            title={<><strong>Create A Beautiful Portfolio &<MarketingTitleBr /> Automatically Back Up Your Work</strong></>}
                            description={<>Authory aggregates everything you've ever written or recorded and generates a beautiful portfolio page so you can showcase, share, and save your life's work.</>}
                            desktopSVG={<svg width="1351" height="224" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M75 168a4 4 0 0 1-4-4v-40a4 4 0 0 1 4-4h44v44a4 4 0 0 1-4 4H75Z" fill="#82AEFF" />
                                <path d="M119.5 79.5H.5V4A3.5 3.5 0 0 1 4 .5h115.5v79Z" stroke="#E4EDFF" />
                                <path d="M179 80.5h-59.5V140a3.5 3.5 0 0 0 3.5 3.5h59.5V84a3.5 3.5 0 0 0-3.5-3.5Z" stroke="#E4EDFF" strokeMiterlimit="10" />
                                <path d="m142.782 91.287-5.254 3.227a1.946 1.946 0 0 0-.64 2.674l18.167 29.677c.226.369.549.669.935.868l6.037 3.115a1.354 1.354 0 0 0 1.973-1.213l-.034-6.803a2.376 2.376 0 0 0-.349-1.228L145.45 91.927a1.939 1.939 0 0 0-2.668-.64Z" fill="#E4EDFF" />
                                <path d="M1239 176h48v44a4 4 0 0 1-4 4h-40a4 4 0 0 1-4-4v-44Z" fill="#FFA858" />
                                <path d="M1287.5 72.5h63V172c0 1.933-1.57 3.5-3.5 3.5h-59.5v-103ZM1195 128.5h40c1.93 0 3.5 1.567 3.5 3.5v43.5H1195c-1.93 0-3.5-1.567-3.5-3.5v-40c0-1.933 1.57-3.5 3.5-3.5Z" stroke="#E4EDFF" />
                            </svg>}
                            mobileSVG={<></>}
                            textMaxWidth={1200}
                            containerMaxWidth={1320}
                            mobileMaxWidth={344}
                            descriptionTextMaxWidth={700}
                            floatingComponent={<HPMainFloatWrapper userVariant={false}>
                                <HPMainBtnWrapper>
                                    <VerticalSpacing top={0} breakpoints={[{ screen: theme.breakpoints.tablet, marginTop: -20 }]}>
                                        <UnstyledLink href={APPLICATION_ROUTES.SIGNUP}>
                                            <MarketingCTA
                                                text="Create your portfolio in 5 minutes"
                                                size={!IS_MOBILE ? CTASize.L : CTASize.M}
                                            />
                                        </UnstyledLink>
                                    </VerticalSpacing>
                                </HPMainBtnWrapper>
                                <VerticalSpacing top={-33}>
                                    <MarketingAllBenefits useBlockVariant />
                                </VerticalSpacing>
                            </HPMainFloatWrapper>}
                        />
                    </VerticalSpacing>
                    <VerticalSpacing top={20} breakpoints={[{ screen: theme.breakpoints.tablet, marginTop: 150 }]}>
                        <MarketingTextBlock
                            blockMaxWidth={820}
                            backgroundColor="white"
                            title={<><HomepageVariantCustomH2>All of your work. All in one place. Automatically.</HomepageVariantCustomH2></>}
                            description={<p>Don't waste any more time creating PDFs of articles, tinkering with a website builder, or compiling hyperlinks to webpages, podcasts, and content that could disappear at any moment. Get a fully updated, easy-to-share portfolio that will win you new work while showcasing what you've already done.</p>}
                        />
                    </VerticalSpacing>
                </div>
                <div>
                    <HomePageVariantCentered>
                        <HomepageVariantCustomH4><strong>1. Keep your life's work safe even when sites go dark.</strong></HomepageVariantCustomH4>
                    </HomePageVariantCentered>
                    <VerticalSpacing top={30}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2><strong>Automate your backups </strong><br />(past, present, and future)</HomepageVariantCustomH2>}
                            description={<>Authory constantly searches for your published content, automatically backing it up to your portfolio when we find it. You get an searchable archive of your work that you can download anytime.</>}
                            svgPosition="left"
                            desktopImgWidth={471}
                            desktopImgHeight={333}
                            desktopImage={"/authory_static_files/images/homepage/sliders/features/protect_desktop.png"}
                            mobileImage={"/authory_static_files/images/homepage/sliders/features/protect_desktop.png"}
                            mobileMaxWidth={378}
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                    <VerticalSpacing top={60}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2>Import your <strong>Website, Email, and PDF</strong> work samples</HomepageVariantCustomH2>}
                            description={<>Website and emails are difficult to showcase. But not anymore! Authory will automatically take screenshots and add them to your account. Plus, you can upload your PDFs, too!</>}
                            svgPosition="left"
                            desktopImgWidth={471}
                            desktopImgHeight={333}
                            desktopImage={"/authory_static_files/images/features/present/types_desktop.svg"}
                            mobileImage={"/authory_static_files/images/features/present/types_mobile.png"}
                            mobileMaxWidth={378}
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                </div>
                <div>
                    <HomePageVariantCentered>
                        <HomepageVariantCustomH4><strong>2. Show-off your experience without building an entire website.</strong></HomepageVariantCustomH4>
                    </HomePageVariantCentered>
                    <VerticalSpacing top={30}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2>Create a <strong>self-updating portfolio</strong></HomepageVariantCustomH2>}
                            description={<VerticalSpacing bottom={0} breakpoints={[{ screen: 1100, marginBottom: 60 }]}>
                                Our self-updating portfolio includes everything you've published (articles, podcasts, and videos) and looks for future media under your name, populating it for you automatically.
                            </VerticalSpacing>}
                            desktopImgWidth={471}
                            desktopImgHeight={333}
                            desktopImage={"/authory_static_files/images/homepage/sliders/features/present_desktop.png"}
                            mobileImage={"/authory_static_files/images/homepage/sliders/features/present_desktop.png"}
                            mobileMaxWidth={378}
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                    <VerticalSpacing top={60}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2>Send and share your <strong>most relevant work samples</strong></HomepageVariantCustomH2>}
                            description={<>Curate your content to create sharable and embeddable collections that are specific to topic, media type, publication, and more. You can also embed your Authory page within your existing personal website to make them easy to find.</>}
                            desktopImgWidth={471}
                            desktopImgHeight={333}
                            desktopImage={"/authory_static_files/images/features/curate/sharing_desktop.png"}
                            mobileImage={"/authory_static_files/images/features/curate/sharing_mobile.png"}
                            mobileMaxWidth={378}
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                </div>
                <div>
                    <HomePageVariantCentered>
                        <HomepageVariantCustomH4><strong>3. Stay on top of your publications even when editors can't.</strong></HomepageVariantCustomH4>
                    </HomePageVariantCentered>
                    <VerticalSpacing top={30}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2>Know when your <strong>content is published</strong></HomepageVariantCustomH2>}
                            description={<>Often we're the first to tell customers their articles have gone live. We send you notifications as we find your work and help you keep track of when and where it appears.</>}
                            desktopImgWidth={471}
                            desktopImgHeight={333}
                            desktopImage={"/authory_static_files/images/homepage/sliders/features/track_desktop.png"}
                            mobileImage={"/authory_static_files/images/homepage/sliders/features/track_desktop.png"}
                            mobileMaxWidth={378}
                            svgPosition="left"
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                    <VerticalSpacing top={60}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2>Keep your <strong>audience up-to-date</strong></HomepageVariantCustomH2>}
                            description={<>Make it easy for readers to follow your work. Send automated email digests with a daily or weekly newsletter that links to your latest content.</>}
                            desktopImgWidth={471}
                            desktopImgHeight={333}
                            desktopImage={"/authory_static_files/images/homepage/sliders/features/distribute_desktop.png"}
                            mobileImage={"/authory_static_files/images/homepage/sliders/features/distribute_desktop.png"}
                            mobileMaxWidth={378}
                            svgPosition="left"
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                </div>
                <div>
                    <HomePageVariantCentered>
                        <HomepageVariantCustomH4><strong>4. Perfect your pitch with a tool as flexible as you are.</strong></HomepageVariantCustomH4>
                    </HomePageVariantCentered>
                    <VerticalSpacing top={30}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2><strong>Robust analytics</strong> you'll always have access to</HomepageVariantCustomH2>}
                            description={<>Get social media analytics for all your content (even if it's under another brand's name) and more easily monitor your work's reach, traction, and social media interactions.</>}
                            desktopImgWidth={524}
                            desktopImgHeight={370}
                            desktopImage={"/authory_static_files/images/homepage/sliders/features/analyze_desktop.png"}
                            mobileImage={"/authory_static_files/images/homepage/sliders/features/analyze_desktop.png"}
                            mobileMaxWidth={378}
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                    <VerticalSpacing top={60}>
                        <MarketingTextSVGColumn
                            homepageVariant
                            title={<HomepageVariantCustomH2>Create <strong>custom collections</strong></HomepageVariantCustomH2>}
                            description={<>Quickly organize your content for each area of focus so you can send a custom page with each pitch, job application, partnership, and more.</>}
                            desktopImgWidth={524}
                            desktopImgHeight={370}
                            desktopImage={"/authory_static_files/images/features/curate/automated_desktop.png"}
                            mobileImage={"/authory_static_files/images/features/curate/automated_mobile.png"}
                            mobileMaxWidth={378}
                            snapBr={theme.breakpoints.desktop}
                        />
                    </VerticalSpacing>
                </div>
                <div>
                    <MarketingCarouselUser
                        homepageVariant
                        text={<><strong>Join the thousands of content creators</strong> who trust Authory to back up and showcase their work</>}
                    />
                </div>
                <div>
                    <VerticalSpacing bottom={-40}>
                        <HomePageVariantCentered>
                            <HomepageVariantCustomH2><strong>We keep your content safe.</strong> That's a promise.</HomepageVariantCustomH2>
                        </HomePageVariantCentered>
                    </VerticalSpacing>
                    <MarketingTextBlock
                        noPadding
                        backgroundColor="white"
                        blockMaxWidth={1800}
                        title={<></>}
                        description={<>
                            <VerticalSpacing top={40}>
                                <p>Our only goal at Authory is to help you and your audience. That's why...</p>
                            </VerticalSpacing>
                            <VerticalSpacing top={80}>
                                <MarketingIconsBlock
                                    useDash={false}
                                    icons={[
                                        <MarketingIconBlock
                                            title={<><strong>Ad- and AI-free,</strong> forever</>}
                                            text={<>Authory doesn't sell your data, neither to PR firms nor to AI companies.<br /> <br />Your data is safe with us. No hidden agendas here.</>}
                                            icon={<SVGGeneric src="/authory_static_files/images/icons/devices_portfolio.svg" />}
                                            minHeight={134}
                                        />,
                                        <MarketingIconBlock
                                            title={<><strong>Copyrights?</strong> Check</>}
                                            text={<>All content added to your Authory account is treated as private (it's 100% copyright compliant), and you're the only one who can access the full-text backup. We will not republish your content.</>}
                                            icon={<SVGGeneric src="/authory_static_files/images/icons/copyrights.svg" />}
                                            minHeight={134}
                                        />,
                                        <MarketingIconBlock
                                            title={<><strong>No lock in,</strong> ever</>}
                                            text={<>We don't lock you in. You can export your entire archive of content at any time and we'll send it to you as an XML or HTML file.</>}
                                            icon={<SVGGeneric src="/authory_static_files/images/icons/no_lock_in.svg" />}
                                            minHeight={134}
                                        />
                                    ]}
                                />
                            </VerticalSpacing>
                            <VerticalSpacing top={40} breakpoints={[{ screen: theme.breakpoints.desktop, marginTop: 0 }]}>
                                <p>Read more about copyright and ownership <InlineLink href={APPLICATION_ROUTES.OWNERSHIP}>here</InlineLink>.</p>
                            </VerticalSpacing>
                        </>}
                        textAlignment="center"
                    />
                </div>
                <div>
                    <HomePageVariantCentered>
                        <HomepageVariantCustomH2 style={{ textAlign: "center" }}><strong>We're for the writers, the creators, the storytellers;</strong><MarketingTitleBr /> for those creating something worth saving.</HomepageVariantCustomH2>
                    </HomePageVariantCentered>
                    <VerticalSpacing top={-10} breakpoints={[{ screen: theme.breakpoints.tablet, marginTop: 40 }]}>
                        <MarketingTextSVGHeader
                            floatingComponent={<HomepageAuthoryTeamImage
                                src="/authory_static_files/images/authory_team.jpg"
                            />}
                            customTitleMarginBottom={0}
                            desktopSVG={<PricingDesktop />}
                            mobileSVG={<></>}
                            textMaxWidth={970}
                            description={<></>}
                            containerMaxWidth={1320}
                            mobileMaxWidth={343}
                        />
                    </VerticalSpacing>
                    <VerticalSpacing top={45} breakpoints={[{ screen: theme.breakpoints.tablet, marginTop: -10 }]}>
                        <MarketingTextBlock
                            blockMaxWidth={820}
                            description={<>
                                <p>We know that as a writer and content creator, your work doesn't end with the final draft. There's working with your team to get it live, reviewing analytics, pitching new projects. Not to mention trying to stay afloat when websites go dark, publication cadence increases, and some people just can't tell the difference between automated text and great writing.</p>
                                <VerticalSpacing top={30}>
                                    <p>That's why we built Authory. So you can more easily find, back up, browse, showcase, and share all of the work you've ever done.</p>
                                </VerticalSpacing>
                            </>}
                            textAlignment="center"
                            noPadding
                        />
                    </VerticalSpacing>
                </div>
                <div>
                    <MarketingG2Banner />
                    <VerticalSpacing top={30}>
                        <MarketingGetStarted
                            ctaLabel="Create your account in 5 minutes"
                            showG2Banner={false}
                            description="Create your portfolio now"
                        />
                    </VerticalSpacing>
                </div>
            </HomepageBlockBackgroundWrapper>
            <VerticalSpacing top={60}>
                <MarketingFooter />
            </VerticalSpacing>
        </FadeUpContainer>
    </>
}